<template>
  <div>
    <v-row align="center">
      <v-col class="pr-12">
        <h2>Overview</h2>
      </v-col>
      <v-col cols="auto">
        <v-btn dark small depressed :loading="pdfLoading" @click="downloadPdf"
          ><v-icon left>mdi-file-pdf-box</v-icon>Cover Sheet</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-card>
          <v-toolbar flat dense dark color="accent">
            <v-toolbar-title>Day Overview</v-toolbar-title>
          </v-toolbar>
          <v-simple-table v-if="itinerary.days.length > 0">
            <thead>
              <tr>
                <th>Date</th>
                <th>Accommodation</th>
                <th>Room Status</th>
                <th>Golf Course</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(day, index) in days" v-bind:key="index">
                <td>
                  <div>
                    <strong>Day {{ day.dayNumber }}</strong>
                  </div>
                  <div v-if="itinerary.start_date_known">
                    {{ getDate(day.dayNumber, index) }}
                  </div>
                </td>
                <td>
                  <ul v-if="day.accommodations.length > 0">
                    <li
                      v-for="accommodation in day.accommodations"
                      v-bind:key="accommodation.id"
                    >
                      {{ accommodation.name }}
                    </li>
                  </ul>
                </td>
                <td>
                  <div>{{ day.room_type }}</div>
                </td>
                <td>
                  <ul v-if="day.courses.length > 0">
                    <li v-for="course in day.courses" v-bind:key="course.id">
                      <div>{{ course.name }}</div>
                      <div>Tee Time: {{ course.pivot.tee_time }}</div>
                      <div>Notes: {{ course.pivot.notes }}</div>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-card-text v-else>
            There are currently no 'days' created for this itinerary
          </v-card-text>
        </v-card>
        <v-card class="mt-8">
          <v-toolbar flat dense dark color="accent">
            <v-toolbar-title>Notes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small depressed @click="$refs.notesForm.openForm()">
              <v-icon left>mdi-plus</v-icon>Add
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-data-table
            :headers="notesTableHeaders"
            :items="itinerary.notes"
            no-data-text="There are no notes for this itinerary"
          >
            <template v-slot:item.text="{ item }">
              <span style="white-space: pre" v-html="item.text"></span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="$refs.deleteNote.openForm(item.id)"
                  >
                    <v-icon small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-toolbar flat dense dark color="accent">
            <v-toolbar-title>Basic Details</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="pt-4">
            <p class="font-weight-bold" v-html="itinerary.intro_text"></p>
            <v-divider class="mt-4 mb-4"></v-divider>
            <h3 class="mb-3">Accommodation</h3>
            <p
              v-if="itinerary.accommodation_summary"
              style="white-space: pre-wrap"
              v-html="itinerary.accommodation_summary"
            ></p>
            <p class="text--disabled" v-else>Empty</p>
            <v-divider class="mt-4 mb-4"></v-divider>
            <h3 class="mb-3">Golf</h3>
            <p
              v-if="itinerary.golf_summary"
              style="white-space: pre-wrap"
              v-html="itinerary.golf_summary"
            ></p>
            <p class="text--disabled" v-else>Empty</p>
            <v-divider class="mt-4 mb-4"></v-divider>
            <h3 class="mb-3">Transport</h3>
            <p
              v-if="itinerary.transport_summary"
              style="white-space: pre-wrap"
              v-html="itinerary.transport_summary"
            ></p>
            <p class="text--disabled" v-else>Empty</p>
            <v-divider class="mt-4 mb-4"></v-divider>
            <h3 class="mb-3">Drumgolf Tour Management Service</h3>
            <p
              v-if="itinerary.drumgolf_summary_text"
              style="white-space: pre-wrap"
              v-html="itinerary.drumgolf_summary_text"
            ></p>
            <p class="text--disabled" v-else>Empty</p>
            <div v-for="price in pricesNotRejected" v-bind:key="price.id">
              <v-divider class="mt-4 mb-4"></v-divider>
              <v-row align="center" no-gutters>
                <v-col cols="8">
                  <h3>{{ price.description }}:</h3>
                </v-col>
                <v-col cols="4">
                  <div class="d-flex flex-column align-end">
                    <h3>(x{{ price.quantity }}) £{{ price.amount }}</h3>
                    <v-chip
                      :color="getStatusColor(price.status)"
                      text-color="white"
                      label
                      x-small
                      >{{ getStatusText(price.status) }}</v-chip
                    >
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <NotesForm ref="notesForm" :itinerary="itinerary" />
    <DeleteNote ref="deleteNote" :itinerary="itinerary" />
  </div>
</template>

<script>
import NotesForm from "../../customers/components/profile/NotesForm";
import DeleteNote from "../../components/DeleteNote";

export default {
  props: {
    itinerary: {
      type: Object,
      required: true,
    },
  },

  components: {
    NotesForm,
    DeleteNote,
  },

  data() {
    return {
      notesTableHeaders: [
        { text: "Date", value: "formatted_dates.created_at" },
        { text: "Note", value: "text" },
        { text: "Noted By", value: "created_by.full_name" },
        { text: "", value: "actions", align: "right", sortable: false },
      ],
      pdfLoading: false,
    };
  },

  computed: {
    days() {
      let days = this.itinerary.days;
      days.forEach((day, index, days) => {
        days[index]["dayNumber"] = index + 1;
      });

      return days;
    },

    pricesNotRejected() {
      return this.itinerary.pricing.filter(price => {
        return price.status !== "Rejected";
      });
    },
  },

  methods: {
    getDate: function(dayNumber, index) {
      if (this.days[index].date) {
        return this.formatDateAsString(this.days[index].date);
      } else if (dayNumber > 1) {
        let date = new Date(this.itinerary.start_date);
        date.setDate(date.getDate() + (dayNumber - 1));

        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const dateString = `${year}-${month}-${day}`;

        return this.formatDateAsString(dateString);
      }

      return this.formatDateAsString(this.itinerary.start_date);
    },
    getStatusColor(status) {
      return status === "Accepted"
        ? "green"
        : status === "Option"
        ? "orange"
        : "red";
    },
    getStatusText(status) {
      return status.charAt(0).toUpperCase() + status.slice(1);
    },

    downloadPdf() {
      const appId = this.$route.params.id;
      const tourId = this.$route.params.tourId;
      const itineraryId = this.$route.params.itineraryId;
      this.pdfLoading = true;

      this.$store
        .dispatch("drum/tours/downloadItineraryCoverPdf", {
          appId,
          tourId,
          itineraryId,
        })
        .then(response => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "itinerary-cover-sheet.pdf";
          link.click();
          this.pdfLoading = false;
        })
        .catch(() => {
          this.pdfLoading = false;
        });
    },
  },
};
</script>
